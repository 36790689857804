<template>
    <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
            offset-y
            ref="menu"
            transition="scale-transition"
            v-model="menu"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    :dense="dense"
                    :label="label"
                    :rules="rules"
                    outlined
                    prepend-inner-icon="event"
                    readonly
                    v-model="date"
                    v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
                :max="max"
                :min="min"
                :show-current="current"
                :type="type"
                @change="save"
                locale="de"
                ref="picker"
                v-model="date"
        ></v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        props: {
            label: String,
            value: String,
            dense: {
                type: Boolean,
                default: false
            },
            rules: Array,
            current: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: "date"
            },
            currentDate: {
                type: String,
                default: ""
            },
            min: String,
            max: {
                type: String,
                default: "2030-01-01"
            }
        },
        data: () => ({
            menu: false
        }),
        computed: {
            date: {
                get() {
                    let d = new Date(this.currentDate);
                    return (d == "Invalid Date") ? null : this.currentDate
                },
                set(val) {
                    this.save(val)
                    this.$emit('input', val)
                }
            },
        },
        mounted() {
            if (this.current)
                this.data = new Date();
        },
        watch: {
            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            }
        },
        methods: {
            save(date) {
                this.$refs.menu.save(date)
            }
        }
    }
</script>